@import './../../styles/colors.css';

.contact-main-container {
  /* border: 2px solid red; */
  margin: 50px auto 50px auto;
  padding: 2px;
  display: block;
  justify-content: center;
  align-items: center;
}

.contact-title-container {
  /* border: 2px solid blue; */
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px 0 20px;
}

.contact-form-container {
  /* border: 2px solid purple; */
  padding: 2px;
  display: flex;
  justify-content: space-around;
}

.contact-container-center {
  /* border: 2px solid green; */
  padding: 2px;
  /* display: flex; */
}

/* ########## LEFT CONTAINER ########## */
.contact-container-left {
  /* border: 2px solid green; */
  padding: 2px;
}

.container-content {
  /* border: 2px solid black; */
  padding: 2px;
  display: block;
}

.contact-info-container {
  /* border: 2px solid purple; */
  padding: 2px;
  display: flex;
}

.contact-info-elements-flex {
  /* border: 2px solid orange; */
  padding: 2px;
  display: flex;
  margin: 5px 0 30px;
}

.contact-icon-info {
  /* border: 2px solid cyan; */
  margin: 6px 8px 0 0px !important;
  color: var(--blue-ncs-2);
}

.contact-info-elements-block {
  /* border: 2px solid yellow; */
  padding: 2px;
  display: block;
}

.contact-advantages-company {
  /* border: 2px solid red; */
  padding: 2px;
  margin-top: 50px;
  display: block;
}

.contact-advantages-title {
  margin-bottom: 20px;
  padding: 2px;
}

.contact-advantages-points {
  /* border: 2px solid black; */
  padding: 2px;
  margin: 5px 0 10px;
  display: flex;
}

/* ########## RIGHT CONTAINER ########## */
/* .contact-container-right {
  border: 2px solid green;
  padding: 2px;
  display: block;
}

.contact-input-form {
  border: 2px solid red;
  padding: 2px;
  display: block;
  padding: 20px 10px;
}

.contact-icon-list {
  margin: 0 5px 0 5px;
}

.contact-input-element {
  border: 2px solid orange;
  padding: 2px;
  margin: 5px 5px 20px 5px;
}

.contact-input-element-2 {
  border: 2px solid orange;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  margin: 5px 5px 20px 5px;
}

.contact-input-md {
  border: 2px solid deeppink;
  padding: 2px;
  width: 48% !important;
}

.contact-button-div {
  border: 2px solid blue;
  padding: 2px;
  margin: 5px;
  width: fit-content;
}

.contact-form-button {
  background-color: transparent;
  width: 180px;
  max-width: 180px;
  line-height: 18px;
  border: 2px solid;
  border-radius: 30px;
  color: var(--button-border-blue);
  transition: background-color 0.5s ease;
}

.contact-form-error {
  color: var(--mui-error-main);
} */

@media screen and (max-width: 500px) {
  .contact-info-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 879px) {
  .contact-main-container {
    width: 95%;
  }

  .contact-form-container {
    display: block;
  }

  .contact-container-left {
    display: none;
  }
}

@media screen and (min-width: 880px) and (max-width: 1499px) {
  .contact-main-container {
    width: 80%;
  }

  .contact-container-left {
    width: 25%;
  }

  .contact-container-right {
    width: 80%;
  }
}

@media screen and (min-width: 1500px) {
  .contact-main-container {
    width: 1200px;
  }

  .contact-container-left {
    width: 350px;
  }

  .contact-container-right {
    width: 800px;
  }
}
