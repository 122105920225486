@import '../../../styles/colors.css';

.sub-header {
  /* border: 2px solid black; */
  align-items: center;
}

@media screen and (min-width: 1500px) {
  .sub-header {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}

@media screen and (min-width: 880px) and (max-width: 1499px) {
  .sub-header {
    display: flex;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 879px) {
  .sub-header {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.sub-header-logo {
  width: 100%;
  max-width: 300px;
  min-width: 240px;
  height: auto;
  display: block;
  padding: 5px;
}

/* ##################### */

.sub-header-container-logo {
  /* border: blue 2px solid; */
  box-sizing: content-box;
  padding: 8px 8px;
  width: 30%;
  display: flex;
  justify-content: center;
}

.sub-header-container-elements {
  /* border: red 2px solid; */
  box-sizing: content-box;
  padding: 8px 8px;
  width: 30%;
  display: flex;
  justify-content: center;
}

.sub-header-container-empty {
  /* border: green 2px solid; */
  width: 30%;
  padding: 8px 8px;
  display: block;
}

@media screen and (max-width: 1499px) {
  .sub-header-container-empty {
    display: none;
  }
}
