#slider-container {
  /* border: red 1px solid; */
  box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.slider {
  display: flex;
  /* flex: 0 0 100%; */
}

@media (max-width: 1920px) {
  .image {
    width: 100%;
  }
}

.image.appear {
  opacity: 1;
  transition: opacity 1s ease;
}

.image.disappear {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease; /* Add transition for opacity */
}
