.service-main-container {
  /* border: 2px solid black; */
  margin: 10px auto;
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
}

/* ########## LEFT SECTION ########## */
.service-left-section {
  /* border: 2px solid red; */
}

.service-image-container {
  /* border: 2px solid pink; */
}

.service-image-style {
  display: block;
  width: 100%;
  border-radius: 10px;
}

.service-text-container {
  /* border: 2px solid green; */
  margin: 5px 0 5px;
  padding: 5px 5px;
}

.service-list-icon {
  width: 18px;
  margin: 6px 2px 0px 2px;
}

.service-inline-flex {
  /* border: 2px solid orchid; */
  display: inline-flex;
}

.service-display-block {
  /* border: 2px solid orange; */
  margin: 0px 5px 0px 5px;
  display: block;
}

/* ########## RIGHT SECTION ########## */
.service-right-section {
  /* border: 2px solid blue; */
  padding: 5px;
  /* display: block; */
}

.service-list-item-button {
  /* border: 2px solid rgb(51, 255, 0); */
  border: none;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  background-color: #f4f4f5;
  border-radius: 3px;
  padding-left: 10px;
  margin-bottom: 5px;
  transition: 0.3s linear;
}

.service-list-item-button:hover {
  background-color: #ffffff;
}

.service-list-item-text {
  /* border: 2px solid blue; */
  font-size: 16px !important;
  line-height: 20px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
  color: var(--main-title-color) !important;
}
/* ########################################### */

/* ########## DYNAMIQUE SIZE ########## */
@media screen and (max-width: 1499px) {
  .service-main-container {
    width: 80%;
    justify-content: center;
  }

  .service-left-section {
    width: 90%;
  }

  .service-right-section {
    display: none;
    width: 25%;
  }
}

@media screen and (min-width: 1500px) {
  .service-main-container {
    width: 1200px;
  }

  .service-left-section {
    width: 875px;
  }

  .service-right-section {
    width: 275px;
  }
}
