@import '../../../styles/colors.css';

.footer-content-container {
  background-color: #f1f1f3;
  display: flex;
  justify-content: center;
  /* border: 2px solid brown; */
}

.footer-content {
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* border: 2px solid violet; */
}

/* Colonnes */

.col-sm-3 {
  width: 30%;
  margin: 5px 5px;
  display: grid;
  justify-items: start;
  float: left;
  /* border: 2px solid #74d900; */
}

/* Colonne n°1 */
.ribbon-footer {
  position: relative;
  top: -24px;
  /* border: 2px solid black; */
}

.ribbon-text {
  max-width: 500px;
  text-align: center;
  margin: auto;
  padding: 0 20px;
  /* border: 2px solid black; */
}

/* Colonne n°2 */
.col-sm-services {
  padding: 10px 5px 10px;
  margin: 5px 5px;
  /* border: 2px solid red; */
  float: left;
}

.services-list-elements {
  list-style-type: none;
  padding-inline-start: 0;
  display: inline-block;
  /* border: 2px solid black; */
}

.service-element {
  display: flex;
  margin-bottom: 10px;
  /* border: 2px solid pink; */
}

/* Colonne n°3 */
.contact-icon-info {
  margin-top: 0 !important;
  margin-left: 2px;
  margin-right: 8px;
  color: var(--info-contact-icon);
}

.contact-list-elements {
  list-style-type: none;
  padding-inline-start: 0;
  justify-items: center;
  align-items: center;
}

.contact-element {
  margin: 10px 0 20px !important;
  display: flex;
  align-items: center;
}

.list-icon {
  width: 18px;
  margin-right: 8px;
}

/* ##### */

/* Footer */

.footer-bottomline-container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0 15px;
  /* border: 2px solid brown; */
}

.footer-bottomline-content {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid blue; */
}

/* ###################################### */

@media screen and (max-width: 820px) {
  .footer-content,
  .footer-bottomline-content {
    width: 450px;
  }

  .col-sm-3 {
    max-width: 300px;
    width: auto;
    padding: 5px 5px;
  }

  .footer-content {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1500px) {
  .footer-content,
  .footer-bottomline-content {
    width: 1350px;
  }
}

/*
  .col-sm-3 {
    width: 45%;
    padding: 5px 5px;
  }

  .footer-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
} */

/* @media screen and (min-width: 1000px) {
  .footer-content,
  .footer-bottomline-content {
    width: 1350px;
  }

  .col-sm-3 {
    width: 30%;
    margin: 5px 5px;
  }

  .footer-content {
    display: flex;
    justify-content: center;
  }
} */
