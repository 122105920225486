:root {
  --blue-ncs-1: #1981dc; /* Blue color used in Net Cleaning logo */
  --green-ncs-1: #57d666; /* Green color used in Net Cleaning logo */

  --blue-ncs-2: #328fe0;
  --green-ncs-2: #6cdb69;

  --blue-ncs-3: #4c9de4;
  --green-ncs-3: #81e08c;

  /* Material UI Palette Color */
  --mui-primary-light: #42a5f5;
  --mui-primary-main: #1976d2;
  --mui-primary-dark: #1565c0;

  --mui-secondary-light: #ba68c8;
  --mui-secondary-main: #9c27b0;
  --mui-secondary-dark: #7b1fa2;

  --mui-success-light: #4caf50;
  --mui-success-main: #2e7d32;
  --mui-success-dark: #1b5e20;

  --mui-error-light: #ef5350;
  --mui-error-main: #d32f2f;
  --mui-error-dark: #c62828;

  --mui-info-light: #03a9f4;
  --mui-info-main: #0288d1;
  --mui-info-dark: #01579b;

  --mui-warning-light: #ff9800;
  --mui-warning-main: #ed6c02;
  --mui-warning-dark: #e65100;

  /* Color for typography */
  --main-title-color: #3d4c58;
  --main-text-color: #606c77;
  --main-text-color-light: #899198;

  /* Color for Custom Components */
  --button-border-blue: #4ba0e8;

  /* Color for SVG Icons */
  --info-contact-icon: #6fbe52;
}
