@import '../../../styles/colors.css';

.main-card {
  /* border: 1px solid orange; */
  /* width: 375px; */
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 15px 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 7px 20px rgba(85, 85, 85, 0.16);
}

.main-card-icon {
  transition: transform 0.2s ease-in-out;
}

.main-card-icon:hover {
  transform: scale(1.2);
}

.main-card-title {
  /* border: 1px solid pink; */
  margin-top: 44px;
  font-size: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 15px;
  padding: 5px 0 5px;
  color: var(--main-title-color);
}

.main-card-list {
  /* border: 1px solid rebeccapurple; */
  list-style-type: none;
  justify-content: center;
  margin: 15px;
  padding: 3px 0 3px;
}

.main-card-list-mission {
  /* border: 1px solid pink; */
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 8px 0 8px;
}

.main-card-info-btn {
  background-color: transparent;
  margin-top: 20px;
  width: 180px;
  max-width: 180px;
  line-height: 18px;
  border: 2px solid;
  border-radius: 30px;
  color: var(--button-border-blue);
  transition: background-color 0.5s ease;
}

.main-card-info-btn:hover {
  background-color: var(--button-border-blue);
}

.main-card-info-btn:hover .main-card-info-txt {
  color: white;
}

.main-card-info-txt {
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  color: #3d4b57;
  transition: background-color 0.5s ease;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .main-card {
    max-width: 300px;
    margin: 5px;
  }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
  .main-card {
    max-width: 300px;
    margin: 5px 5px;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .main-card {
    max-width: 320px;
    margin: 5px 5px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1120px) {
  .main-card {
    max-width: 320px;
    margin: 10px 10px;
  }
}

@media screen and (min-width: 1121px) and (max-width: 1499px) {
  .main-card {
    max-width: 330px;
    margin: 15px 15px;
  }
}

@media screen and (min-width: 1500px) {
  .main-card {
    max-width: 350px;
    margin: 15px 15px;
  }
}
