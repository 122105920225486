@import '../../../styles/colors.css';

.defaultText {
  color: black;
  text-decoration: none;
}

/* ##### CUSTOM TYPO FOR HEADER'S BUTTON ##### */
.headerButtonText {
  color: var(--mui-primary-main) !important;
  font-family: 'Poppins' sans-serif !important;
  font-weight: bold !important;
  text-decoration: solid !important;
}

/* ##### CUSTOM TYPO FOR CONTENT SECTION ##### */
.contentMainTitle {
  font-size: 40px !important;
  line-height: 60px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: var(--main-title-color) !important;
}

.contentMainText {
  font-size: 16px !important;
  line-height: 27px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400 !important;
  color: var(--main-text-color) !important;
}

.contentListText {
  margin-top: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: var(--main-text-color);
}

/* ##### CUSTOM TYPO FOR SERVICES PAGES ##### */

.serviceTitleText {
  font-size: 30px !important;
  line-height: 50px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: var(--main-title-color) !important;
}

.serviceNormalText {
  font-size: 16px !important;
  line-height: 27px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  color: var(--main-text-color-light) !important;
}

.serviceQuestionText {
  /* border: 2px solid red; */
  margin: 0px !important;
  font-size: 24px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: var(--main-title-color) !important;
}

.serviceMissionTitle {
  font-size: 16px !important;
  line-height: 27px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 600 !important;
  color: var(--main-text-color) !important;
}

/* ##### CUSTOM TYPO FOR FOOTER SECTION ##### */
.footerTitle {
  font-family: 'Poppins', sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: var(--main-title-color);
}

.footerText {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: var(--main-text-color);
}

.footerCopyright {
  font-family: 'Roboto', sans-serif !important;
  color: #899198 !important;
  font-size: 16px !important;
  line-height: 27px !important;
  font-weight: 400 !important;
}
