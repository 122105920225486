@import './../../../styles/colors.css';

@keyframes fadeInButton {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutButton {
  0% {
    opacity: 1;
    /* transform: translateY(0); */
  }
  100% {
    opacity: 0;
    /* transform: translateY(20px); */
  }
}

.scroll-button-here {
  background-color: var(--info-contact-icon) !important;
  color: white !important;
  position: fixed !important;
  bottom: 70px !important; /* Adjust the distance from the bottom as needed */
  right: 20px !important;
  z-index: 1000 !important;
  animation: fadeInButton 0.5s ease-in-out;
}

.scroll-button-hidden {
  display: none !important;
  animation: fadeOutButton 1.5s ease-in-out;
}
