.main-container {
  /* border: red 2px solid; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content-container {
  /* border: blue 2px solid; */
  margin-top: 10px;
  max-width: 700px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.card-carousel {
  /* border: 2px solid black !important; */
  /* margin: 15px 15px; */
  /* padding: 15px 15px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

@media screen and (max-width: 500px) {
  .card-carousel {
    padding: 0px;
  }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
  .card-carousel {
    width: 80%;
    padding: 5px 5px;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .card-carousel {
    width: 70%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1120px) {
  .card-carousel {
    width: 70%;
  }
}

@media screen and (min-width: 1121px) and (max-width: 1499px) {
  .card-carousel {
    width: 80%;
  }
}

@media screen and (min-width: 1500px) {
  .card-carousel {
    width: 1350px;
  }
}
