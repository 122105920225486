.headerButton {
  padding: 10px 20px !important;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: transparent !important;
}

.headerButton:hover {
}

.headerButton.active {
}

.serviceListButton {
  border: 1px solid black;
  background-color: #f2f4f5;
  border-radius: 5px;
}
