#screen-container {
  /* border: 2px solid black; */
  height: 100vh;
  display: flex;
  justify-content: center;
  text-align: center;
}

#logo-container {
  /* border: 2px solid blue; */
  margin: auto;
  width: fit-content;
  height: fit-content;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

#ncs-loader-logo {
  animation: fadeIn 1.5s ease-in-out, fadeOut 1.5s ease-in-out 1.5s;
}

@media screen and (max-width: 999px) {
  #ncs-loader-logo {
    width: 80vw;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1499px) {
  #ncs-loader-logo {
    width: 70vw;
  }
}

@media screen and (min-width: 1500px) {
  #ncs-loader-logo {
    width: 50vw;
  }
}
